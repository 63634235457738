<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      :exportShow="false"
    >
    </EForm>
<!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
<!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      @changeSize="changeSize"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
      <div slot="registProvince" slot-scope="scope">
        {{scope.data.registProvince+scope.data.registCity+scope.data.registArea}}
      </div>
      <div slot="curentProvince" slot-scope="scope">
        {{scope.data.curentProvince+scope.data.curentCity+scope.data.curentArea}}
      </div>
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import {mapGetters} from 'vuex'
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '订单号',
            prop: 'tradeNum',
          },
          {
            label: '买方姓名',
            prop: 'buyerName',
          },
          {
            label: '买方手机号',
            prop: 'buyerPhone',
          },
          {
            label: '卖方姓名',
            prop: 'salerName',
          },
          {
            label: '卖方手机号',
            prop: 'salerPhone',
          },
          {
            label: '订单金额',
            prop: 'totalAmount',
          },
          {
            label: '付款方式',
            prop: 'payWay',
          },
          {
            label: '交易时间',
            prop: 'tradeTime',
          },

        ],
        formColumns: [
          {
            title: '买家姓名',
            type: 'text',
            property: 'buyerName',
            show: true,
          },

          {
            title: '买家手机号',
            type: 'text',
            property: 'buyerPhone',
            show: true,
          },
          {
            title: '卖家姓名',
            type: 'text',
            property: 'salerName',
            show: true,
          },
          {
            title: '卖家手机号',
            type: 'text',
            property: 'salerPhone',
            show: true,
          },
          {
            title: '交易起始时间 ',
            type: 'datePicker',
            property: 'tradeTimeStart',
            show: true,
          },
          {
            title: '交易截止时间',
            type: 'datePicker',
            property: 'tradeTimeEnd',
            show: true,
          },
          {
            title: '付款方式',
            type: 'select',
            property: 'payWay',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '现金',
                value: '现金',
              },
              {
                label: '微信',
                value: '微信',
              },
              {
                label: '支付宝',
                value: '支付宝',
              },
            ],
          },
          {
            title: '订单编号',
            type: 'text',
            property: 'tradeNum',
            show: true,
          }
        ],
        tableData: [],
        searchForm: {
          buyerName:null,
          buyerPhone: null,
          salerName: null,
          salerPhone: null,
          tradeTimeStart: null,
          tradeTimeEnd: null,
          payWay: null,
          tradeNum: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},

      };
    },
    watch: {},
    created() {
      this.getData();

    },
    components: { ETable, EDialog, EButton, EForm },
    computed:{
      ...mapGetters(['buttonList']),
    },
    beforeMount() {},
    methods: {


      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },

      async getData() {
        let res = await Http.appTradeList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.count = res.data.total;
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
.ws {
  width: 100%;
}
</style>
